import React, { createRef, Component } from 'react';
import { Segment, Header, Dimmer, Loader, Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { store } from '../../store';
import { postWithAuth } from '../../api';
import additionalMessages from '../../messages';
import {
  fetchSites,
  uiConfirmAction,
  panelNeedsRefresh,
  setBulkEditSelectedRows,
} from '../mainframe/mainframe.reducer';
import { makePlural, postBulkUpdates } from '../../utils/useful_functions';
import LPGrid from 'src/components/controls/lpgrid/LPGrid';
import messages from '../../messages';
import {
  ADD_EDIT_TOWERSWITCH,
  CONVERT_TO_NETWORK_SITE,
  CONVERT_TO_SUBSCRIBER_SITE,
  CREATE_DUPLICATE_NETWORK_SITE,
  CREATE_DUPLICATE_SUBSCRIBER_SITE,
  DELETE_ACTION_TOOLBAR,
  DELETE_TOWERSWITCH,
  METER_TO_FEET,
} from 'src/app.constants';
import NetworkSiteBomModal from 'src/pages/sites/NetworkSiteBomModal';
import { toast } from 'react-toastify';
import { TOAST_AUTOCLOSE } from '../terragraph/TerragraphPanel';

const NETWORK_SITE_EXTRA_COLUMNS = [
  {
    field: 'properties.ptp_link_count',
    headerName: 'PTP Links',
  },
  {
    field: 'properties.access_point_count',
    headerName: 'PMP Network Devices',
  },
  {
    field: 'properties.mesh_link_count',
    headerName: 'Mesh Links',
  },
  {
    field: 'properties.total_sms',
    headerName: 'Number of Subscriber Modules',
  },
  {
    field: 'properties.connected_subscribers',
    headerName: 'Connected Subscribers',
  },
  {
    field: 'properties.unconnected_subscribers',
    headerName: 'Unconnected Subscribers',
  },
  {
    field: 'properties.dl_throughput',
    headerName: 'Total Predicted DL Throughput',
    isComparatorRequired: true,
  },
  {
    field: 'properties.ul_throughput',
    headerName: 'Total Predicted UL Throughput',
    isComparatorRequired: true,
  },
  {
    field: 'properties.total_throughput',
    headerName: 'Total Throughput',
    isComparatorRequired: true,
  },
  {
    field: 'properties.site_owner',
    headerName: 'Site Owner',
  },
  {
    field: 'properties.has_tower_switch',
    headerName: 'Switch',
    cellRenderer: 'yesNoRenderer',
  },
];

function getExtraNetworkSiteColumns(updateSiteCallback) {
  return [
    {
      field: 'node_type',
      headerName: 'Node Type',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellClass: ['select-cell'],
      cellEditorParams: {
        values: ['', 'DN', 'POP'],
      },
      onCellValueChanged: function (rowData) {
        const { newValue, api, data, objectPath = 'node_type' } = rowData;
        let selectedIds = [data.id];
        const selectedRows = api?.getSelectedRows();

        if (api != null && selectedRows.length) {
          selectedIds = [];
          selectedRows.forEach((row) => {
            const { id } = row;
            row.node_type = newValue;
            selectedIds.push(id);
          });
          api.applyTransaction({ update: selectedRows });
          store.dispatch(
            setBulkEditSelectedRows(JSON.parse(JSON.stringify(selectedRows)))
          );
        }

        if (api) {
          updateSiteCallback(selectedIds, objectPath, newValue, api);
        }
      },
    },
    ...NETWORK_SITE_EXTRA_COLUMNS,
  ];
}

function deleteTowerSwitch(params, projectId, formatMessage, tableRef) {
  const selectedRows = params.gridApi.getSelectedRows();
  const selectedSiteIds = selectedRows.map((row) => row.id);
  store.dispatch(
    uiConfirmAction({
      header: 'Delete Tower Switches',
      message: formatMessage(additionalMessages.confirm),
      size: 'mini',
      onConfirm: () => {
        postWithAuth(
          `project/${projectId}/site/${selectedSiteIds[0]}/tower_switch`,
          {
            site_ids: selectedSiteIds.slice(1),
          },
          'DELETE'
        )
          .then(() => {
            if (tableRef.current) {
              tableRef.current.refresh();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      },
    })
  );
}

class SitesPanelContainer extends Component {
  constructor(props) {
    super(props);
    this.tableRef = createRef();
  }
  deleteSelection = (
    formatMessage,
    projectId,
    selectedItems,
    path,
    panelKind,
    tableRef
  ) => {
    store.dispatch(
      uiConfirmAction({
        header: formatMessage(additionalMessages.deleteSites),
        message: formatMessage(additionalMessages.confirm),
        size: 'mini',
        onConfirm: () => {
          postWithAuth(
            `project/${projectId}/sites`,
            {
              kind: panelKind,
              ids: selectedItems,
            },
            'DELETE'
          ).then(() => {
            store.dispatch(fetchSites(projectId));
            this.setState({ selectedItems: [] });
            if (tableRef) {
              tableRef.current?.refresh();
            }
          });
          // while deleting from table we should retain in same
        },
      })
    );
  };

  refreshTable = () => {
    if (this.tableRef?.current?.refresh) this.tableRef?.current?.refresh();
  };

  createSites = (params, isSiteConversion = false) => {
    const selectedRows = params.gridApi.getSelectedRows();
    const sites = selectedRows.map((row) => {
      return {
        name: row.name,
        latitude: row.latitude,
        longitude: row.longitude,
        is_network_site: this.props.panelKind !== 'network_site',
        maximum_height: row.maximum_height,
        description: row.description,
      };
    });

    let errorMsg = '';
    postWithAuth(`project/${this.props.projectId}/sites`, sites, 'POST').catch(
      (err) => {
        errorMsg = err.detail;
        console.error(err);
      }
    );

    if (errorMsg) {
      toast(<Message negative>{errorMsg}</Message>, {
        autoClose: false,
      });
    } else {
      store.dispatch(fetchSites(this.props.projectId));
      this.setState({ selectedItems: [] });
      this.refreshTable();

      const label = makePlural(sites.length, 'site', 'sites');
      const changeType = isSiteConversion ? 'converted' : 'duplicated';
      const message = `${sites.length} ${label} ${changeType}`;

      toast(<Message positive>{message}</Message>, {
        autoClose: TOAST_AUTOCLOSE,
      });
    }
  };

  state = {
    showTowerSwitch: false,
    selectedFirstSiteId: '',
    selectedSiteIds: [],
    showLoader: false,
    showList: true,
    activeStep: 1,
    fileData: [],
    selectedItems: this.props.selectedItems,
    lpGridActions: [
      Object.assign({
        icon: 'trash alternate',
        label: this.props.intl.formatMessage(messages.delete),
        onClick: (event, data, params) => {
          const selectedRows = params.gridApi.getSelectedRows();
          const selectedSiteIds = selectedRows.map((row) => row.id);
          this.deleteSelection(
            this.props.intl.formatMessage,
            this.props.projectId,
            selectedSiteIds,
            null,
            this.props.panelKind,
            this.tableRef
          );
        },
        disabled: true,
        ...DELETE_ACTION_TOOLBAR,
      }),
    ],
    lpGridSwitchActions: [
      {
        icon: 'plus',
        label: 'Add/Edit Tower Switch',
        id: ADD_EDIT_TOWERSWITCH,
        onClick: (event, data, params) => {
          this.setState({
            selectedFirstSiteId: params.gridApi.getSelectedRows()[0].id,
            showTowerSwitch: true,
            selectedSiteIds: params.gridApi
              .getSelectedRows()
              .map((row) => row.id),
          });
        },
        disabled: true,
      },
      {
        icon: 'trash alternate',
        label: 'Delete Tower Switch',
        id: DELETE_TOWERSWITCH,
        onClick: (event, data, params) => {
          deleteTowerSwitch(
            params,
            this.props.projectId,
            this.props.intl.formatMessage,
            this.tableRef
          );
        },
        disabled: true,
      },
    ],
    conversionDuplicationActions: [
      {
        icon: 'exchange',
        label:
          this.props.panelKind === 'network_site'
            ? 'Convert to subscriber site'
            : 'Convert to network site',
        id:
          this.props.panelKind === 'network_site'
            ? CONVERT_TO_SUBSCRIBER_SITE
            : CONVERT_TO_NETWORK_SITE,
        onClick: (event, data, params) => {
          const selectedRows = params.gridApi.getSelectedRows();
          store.dispatch(
            uiConfirmAction({
              header: 'Convert Site?',
              message:
                this.props.panelKind === 'network_site'
                  ? this.props.intl.formatMessage(
                      messages.convertNetworkSiteMessage
                    )
                  : this.props.intl.formatMessage(
                      messages.convertSubscriberSiteMessage
                    ),
              size: 'mini',
              onConfirm: () => {
                this.createSites(params, true);
                postWithAuth(
                  `project/${this.props.projectId}/sites`,
                  {
                    kind: this.props.panelKind,
                    ids: selectedRows.map((el) => el.id),
                  },
                  'DELETE'
                )
                  .then(() => {
                    store.dispatch(fetchSites(this.props.projectId));
                    this.setState({ selectedItems: [] });
                    this.refreshTable();
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              },
            })
          );
        },
        disabled: this.state?.selectedItems.length | true,
      },
      {
        icon: 'exchange',
        label:
          this.props.panelKind === 'network_site'
            ? 'Create duplicate subscriber site'
            : 'Create duplicate network site',
        id:
          this.props.panelKind === 'network_site'
            ? CREATE_DUPLICATE_SUBSCRIBER_SITE
            : CREATE_DUPLICATE_NETWORK_SITE,
        onClick: (event, data, params) => {
          this.createSites(params);
        },
        disabled: this.state?.selectedItems.length | true,
      },
    ],
  };

  componentDidMount() {
    this.setState({ selectedItems: [] });
  }

  componentDidUpdate(prevProps) {
    const { needsRefresh } = this.props;
    const { subscriberSitePanel, networkSitePanel } = needsRefresh;
    if (subscriberSitePanel || networkSitePanel) {
      // we see instance where ref type AGGridReact is available but refresh is
      // null, suspecting two isses we are accessing it refresh before it is
      // availabe or memory which need to be handled in unmount
      this.refreshTable();
      store.dispatch(
        panelNeedsRefresh({
          panels: networkSitePanel
            ? ['networkSitePanel']
            : ['subscriberSitePanel'],
          status: false,
        })
      );
    }

    if (
      (prevProps.heightUnits != this.props.heightUnits ||
        prevProps.sites?.features.length !=
          this.props.sites?.features.length) &&
      this.tableRef?.current?.refresh
    ) {
      this.tableRef?.current?.refresh(false);
    }
  }

  resetShowTowerSwitch = (value) => {
    this.setState({
      showTowerSwitch: value,
    });
    this.refreshTable();
  };

  updateSite = (ids, objectPath, value, gridApi, heightUnits) => {
    const { projectId } = this.props;
    postBulkUpdates(
      projectId,
      ids,
      objectPath,
      value,
      gridApi,
      'site',
      heightUnits,
      fetchSites
    );
  };
  render() {
    const {
      heading,
      projectName,
      groupKind,
      panelKind,
      projectId,
      listErrorMessage,
    } = this.props;
    const { formatMessage } = this.props.intl;
    const {
      lpGridActions,
      lpGridSwitchActions,
      conversionDuplicationActions,
      selectedFirstSiteId,
    } = this.state;

    const sites = this.props.sites
      ? this.props.sites.features.map((i) => {
          return i.properties;
        })
      : [];
    const itemCount = sites.length;

    const columns = [
      {
        field: 'name',
        sort: 'asc',
        headerName: 'Name',
        editable: true,
        pinned: 'left',
        cellRenderer: 'linkRenderer',
        cellClass: ['custom-text'],
        cellRendererParams: {
          getHref: (data) => `${this.props.path}/${data.id}`,
        },
        onCellValueChanged: (rowData) => {
          const { newValue, oldValue, api, data } = rowData;
          data.name = newValue != '' ? newValue : oldValue;
          api?.applyTransaction({ update: [data] });
          if (newValue !== '' && newValue !== oldValue) {
            const postObj = {
              name: newValue,
              latitude: data.latitude,
              longitude: data.longitude,
            };
            postWithAuth(
              `project/${projectId}/site/${data.id}`,
              postObj,
              'PATCH'
            )
              .then(() => {})
              .catch(() => {});
          }
        },
      },
      {
        field: 'latitude',
        headerName: 'Latitude',
      },
      {
        field: 'longitude',
        headerName: 'Longitude',
      },
      {
        field: 'maximum_height',
        headerValueGetter: ({ context }) => {
          return `Maximum Height (${context.prefs.heightUnits})`;
        },
        headerName: 'Maximum Height',
        cellRenderer: 'heightRenderer',
        cellEditor: 'numberEditor',
        cellClass: ['number-cell'],
        cellEditorParams: {
          precision: 1,
          min: 0,
          max: 3000,
          step: 0.1,
        },
        onCellValueChanged: (rowData) => {
          const {
            data,
            newValue,
            oldValue,
            api,
            objectPath = 'maximum_height',
            context,
          } = rowData;
          let selectedIds = [data.id];
          const { prefs } = context;
          let selectedRows = api?.getSelectedRows();
          if (!selectedRows?.length) selectedRows = [data];
          if (api != null && selectedRows?.length) {
            selectedIds = [];
            selectedRows.forEach((row) => {
              if (prefs.heightUnits === 'ft') {
                row.maximum_height = parseFloat(newValue) / METER_TO_FEET;
              } else {
                row.maximum_height = parseFloat(newValue);
              }
              row.updated = false;
              selectedIds.push(row.id);
            });
            api.applyTransaction({ update: selectedRows });
          }
          if (parseFloat(newValue) !== parseFloat(oldValue) && api != null) {
            this.updateSite(
              selectedIds,
              objectPath,
              newValue,
              api,
              prefs.heightUnits
            );
          }
        },
      },
      {
        field: 'description',
        editable: true,
        headerName: 'Description',
        cellClass: ['ag-grid-cell-truncate-text'],
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: {
          maxLength: '300',
          cols: '50',
          rows: '6',
        },
        onCellValueChanged: (rowData) => {
          const { newValue, api, data, objectPath = 'description' } = rowData;
          let selectedIds = [data.id];
          const selectedRows = api?.getSelectedRows();
          if (api != null && selectedRows.length) {
            selectedIds = [];
            selectedRows.forEach((row) => {
              const { id } = row;
              row.description = newValue;
              selectedIds.push(id);
            });
            api.applyTransaction({ update: selectedRows });
          }
          if (api) this.updateSite(selectedIds, objectPath, newValue, api);
        },
      },
    ];

    if (panelKind === 'network_site') {
      const additionalColumns = getExtraNetworkSiteColumns(this.updateSite);
      columns.push(...additionalColumns);
    } else if (panelKind === 'subscriber_site') {
      columns.push({
        field: 'properties',
        headerName: 'PMP Links',
        valueGetter: (params) => {
          return params.data.properties.subscriber_count;
        },
      });
    }
    const tableView = (
      <Segment basic style={{ width: '100%', maxHeight: '80vh' }}>
        <LPGrid
          url={`project/${projectId}/sites?kind=${panelKind}`}
          gridRef={this.tableRef}
          actions={
            panelKind === 'network_site'
              ? [
                  ...lpGridActions,
                  ...lpGridSwitchActions,
                  ...conversionDuplicationActions,
                ]
              : [...lpGridActions, ...conversionDuplicationActions]
          }
          table_id={panelKind}
          isTableColConfigure={true}
          //   refreshOn={needsRefresh}
          defaultCsvExportParams={{
            fileName: `${projectName}_${groupKind}.csv`,
          }}
          columnDefs={columns}
        ></LPGrid>
      </Segment>
    );

    return (
      <>
        {this.state.showTowerSwitch && (
          <NetworkSiteBomModal
            objId={selectedFirstSiteId}
            kind="network_site"
            url={`project/${this.props.projectId}/site/${selectedFirstSiteId}/bom`}
            tsurl={`project/${this.props.projectId}/site/${selectedFirstSiteId}/tower_switch`}
            selectedSiteIds={this.state.selectedSiteIds}
            tableViewShowTowerSwitch={this.state.showTowerSwitch}
            tableViewResetShowTowerSwitch={(value) =>
              this.resetShowTowerSwitch(value)
            }
          />
        )}
        <Segment basic fireOnMount>
          <Header>
            {formatMessage(heading)} ({itemCount})
          </Header>
          <Dimmer inverted active={this.state.showLoader}>
            <Loader size="large" indeterminate content="Loading..." />
          </Dimmer>
          {listErrorMessage && (
            <div>
              <p class="error-text">{listErrorMessage}</p>
            </div>
          )}
          <div className="detailWrapper">{tableView}</div>
        </Segment>
      </>
    );
  }
}

function WrapSitesPanelContainer(props) {
  const location = useLocation();
  const pathNameArr = location.pathname.split('/');
  const navigate = useNavigate();
  return (
    <SitesPanelContainer
      {...props}
      navigate={navigate}
      selectedId={pathNameArr.length === 3 ? pathNameArr[2] : null}
    />
  );
}

const SitesPanel = React.memo(
  connect((state, ownProps) => {
    const { project } = state;
    const { groupKind } = ownProps;
    const sites = {
      network_sites: state.mainFrame.networkSites,
      subscriber_sites: state.mainFrame.subscriberSites,
    }[groupKind];
    const { projectId, projectName, permissionWrite, prefs, needsRefresh } =
      state.mainFrame;
    const { latLngFormat, heightUnits } = prefs;
    const { selectedItems, listErrorMessage } = state.sites;
    return {
      project,
      sites,
      projectId,
      projectName,
      permissionWrite,
      selectedItems,
      listErrorMessage,
      latLngFormat,
      heightUnits,
      needsRefresh,
      ...ownProps,
    };
  })(WrapSitesPanelContainer)
);

export const NetworkSitesPanel = injectIntl(
  React.memo((props) => (
    <SitesPanel
      heading={additionalMessages.networkSites}
      path="/network_sites"
      panelKind="network_site"
      groupKind="network_sites"
      newSiteIcon="site-icon"
      {...props}
    />
  ))
);

export const SubscriberSitesPanel = injectIntl(
  React.memo((props) => (
    <SitesPanel
      heading={additionalMessages.subscriberSites}
      path="/subscriber_sites"
      panelKind="subscriber_site"
      groupKind="subscriber_sites"
      newSiteIcon="subscriber-site-icon"
      {...props}
    />
  ))
);
